
.square-background-0 {
    background: #666;
}

.square-background-1 {
    background: #b48c42;
}

.square-background-2 {
    background: #517a29;
}

.square-background-3 {
    background: #444;
}

.square-background-1 {
    background: #b48c42;
}

.board-row:after {
    clear: both;
    content: "";
    display: table;
}

.status {
    margin-bottom: 10px;
    font-size: 24px;
}

.square {
    border: 2px solid #777;
    float: left;
    font-size: 44px;
    font-weight: bold;
    margin: 5px;
    padding: 0;
    text-align: center;
    /*line-height: 68px;*/
    height: 50px;
    /*!*height: 25vh;*!*/
    width: 50px;
    /*width: 25vh;*/
    display: inline-block;
}

.square:hover {
    cursor: default;
}

.square:focus {
    outline: none;
}

.kbd-navigation .square:focus {
    background: #ddd;
}

.Game {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #282c34;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;

}

.game-board {
    margin-bottom: 5px;
    margin-top: 10px;
}




