
.flex-item {
    font-weight: bold;
    text-align: center;
}


.flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    /*border: 1px solid silver;*/
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
}

.nowrap  {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.wrap    {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}